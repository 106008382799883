import { useState } from 'react';
import classnames from 'classnames';
import { useStoreState } from 'easy-peasy';

import { Icon, Button, Input } from '@webfx/core-web';
import { useNotifications, useSharedLink, useAuth } from '@webfx/web-hooks';
import { Dialog } from '@webfx/web-ui';

import { buildArticleUrl } from '@webfx/common-web/src/ui/pages/HelpCenter/utils/utils.js';

const ArticleShareLink = ({ category, article, className = '', children }) => {
  const [showLink, setShowLink] = useState(false);
  const [sharedLink, setSharedLink] = useState(null);
  const url = useStoreState((state) => state.router.url);
  const { toast } = useNotifications();
  const { isGuest } = useAuth();
  const { generateShareLink } = useSharedLink();

  // Allow guest users to access the help center
  const accessibleUrl = url('helpCenter');

  const generateLink = async () => {
    const link = await generateShareLink({
      path: buildArticleUrl({ url, article, category }),
      accessibleUrls: [accessibleUrl],
    });
    setSharedLink(link);
    setShowLink(true);
  };

  if (isGuest) {
    return null;
  }

  return (
    <>
      <div
        className={classnames(className, 'd-flex cursor-pointer')}
        onClick={generateLink}
        data-fx-name="shareIcon"
      >
        {children ?? (
          <div className="d-flex flex-shrink-0 align-items-center text-gray-500 text-hover-gray-600 cursor-pointer">
            <Icon className="font-24" outlined>
              content_copy
            </Icon>
            <span className="ml-1 font-13 text-gray-700" data-fx-name="copyUrl">
              Copy URL
            </span>
          </div>
        )}
      </div>

      <Dialog open={showLink} onClose={() => setShowLink(false)}>
        <Dialog.Panel style={{ width: '500px' }}>
          <Dialog.Title className="d-flex align-items-center font-18 border-bottom pb-2 mb-3">
            <div className="d-flex align-items-center" data-fx-name="shareArticleHeader">
              <Icon outlined className="text-blue-vivid-300">
                share
              </Icon>
              <span className="ml-2 bold">Share this Article</span>
            </div>
          </Dialog.Title>

          <div className="d-flex align-items-center alert alert-primary mt-1 mb-0 font-12 semibold mb-2">
            <Icon className="font-18 mr-2" outlined>
              info
            </Icon>
            <p className="m-0">
              Anyone with or without a MarketingCloudFX login will be able to access this article if
              you provide them with the share url.
            </p>
          </div>

          <Input value={sharedLink} readOnly />
          <div className="d-flex justify-content-center">
            <Button
              className="mt-3"
              variant="primary"
              text="Copy URL To Clipboard"
              icon="content_copy"
              onClick={() => {
                navigator.clipboard.writeText(sharedLink);
                toast.success('Link copied to clipboard!');
                setShowLink(false);
              }}
              data-fx-name="copyUrlButton"
            />
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
};

export default ArticleShareLink;
