import React, { useEffect, useState } from 'react';
import { ContentContainer, Spinner, Icon, Paper, useCan } from '@webfx/core-web';
import { useAuth, useFlags, useDocumentTitle, useNutshellAuth } from '@webfx/web-hooks';
import classnames from 'classnames';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Nav, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { NutshellEmbedFrame, NutshellLoading, NutshellEmbed } from '@webfx/web-ui-connected';
import useCurrentUserNutshell from '../../../hooks/useCurrentUserNutshell';
const Schedules = React.lazy(() => import('@webfx/schedulerfx-web/src/ui/pages/Schedules')); // TODO: figure out how to abstract this

import styles from './MyInfo.module.css';
import Profile from './Profile';
import Tokens from './Tokens';

const EmailNotifications = React.lazy(() => import('./EmailNotifications'));

/**
 *
 */
const MyInfo = () => {
  const [showSchedulerModal, setShowSchedulerModal] = useState(false);
  const url = useStoreState((state) => state.router.url);
  const { user = {}, isAdmin } = useAuth();
  const meetWithMeSlug = useStoreState((state) => state.scheduler.meetWithMeSlug);
  const { onGetMeetWithMeSlug } = useStoreActions((actions) => actions.scheduler);
  const flags = useFlags();
  const canViewScheduler = useCan('read', 'scheduler');
  const canViewNutshell =
    useCan('manage', 'nutshell') && flags.schedulerFxNutshellForFXers && !isAdmin;
  const nsId = useCurrentUserNutshell();
  const nsSiteId = user.sites.filter((site) => site.nsInstanceId === nsId)[0]?.siteId;
  const canViewNotifications = flags.iamEmailpreferences;
  useDocumentTitle('My Info | MarketingCloudFX');

  useEffect(() => {
    // Select the node that will be observed for mutations
    const targetNode = document.body;

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = function (mutationsList) {
      // Use traditional 'for loops' for IE 11
      for (let i = 0; i <= mutationsList.length; i++) {
        if (mutationsList[i] && mutationsList[i].type === 'childList') {
          if (
            mutationsList[i].removedNodes.length > 0 &&
            mutationsList[i].removedNodes[0].className === 'nylas-modal nylas-ready'
          ) {
            setShowSchedulerModal(false);
          }
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);

    // Later, you can stop observing
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    onGetMeetWithMeSlug(user.userId);
  }, []);

  const toggleShowSchedulerModal = () => setShowSchedulerModal((state) => !state);

  return (
    <ContentContainer>
      {showSchedulerModal && <Schedules toggleShowSchedulerModal={toggleShowSchedulerModal} />}
      <Paper>
        <div className="d-flex">
          <Tab.Container id="view-nav" defaultActiveKey="profile">
            <aside className={classnames(['col-3', styles.sidebar])}>
              <Nav variant="pills" className="flex-column">
                <NavItem label={'Profile'} icon={'person'} eventKey={'profile'} />
                <NavItem label={'Api Keys'} icon={'vpn_key'} eventKey="tokens" />
                {(canViewScheduler || canViewNutshell) && flags.schedulerNylas && (
                  <NavItem
                    label={
                      isAdmin && flags.schedulerNylas
                        ? 'Manage Meeting Types (legacy)'
                        : 'Manage Meeting Types'
                    }
                    icon="today"
                    eventKey="scheduler-legacy"
                    data-fx-name="manageMeetingTypes"
                    {...(canViewNutshell && !isAdmin && nsId
                      ? {
                          href: url({
                            route: 'mcfxScheduler',
                            siteId: user?.sites?.find((site) => site.nsInstanceId === nsId)?.siteId,
                          }),
                        }
                      : {})}
                    {...(!canViewNutshell && {
                      onClick: () => {
                        setShowSchedulerModal(true);
                      },
                    })}
                  />
                )}

                {canViewScheduler && meetWithMeSlug && flags.schedulerNylas && (
                  <NavItem
                    label={
                      isAdmin && flags.schedulerNylas
                        ? 'Meet With Me Page (legacy)'
                        : 'Meet With Me Page'
                    }
                    icon="mood"
                    eventKey="meetWithMe"
                    to={url({ route: 'schedulerMeeting', id: meetWithMeSlug })}
                    target="_blank"
                    as={Link}
                  />
                )}
                {isAdmin && flags.schedulerFxNutshellForFXers && (
                  <NavItem label={'SchedulerFX'} icon="mood" eventKey="schedulerFx" />
                )}
                {canViewNotifications && (
                  <NavItem label="Email Notifications" icon="email" eventKey="emailNotifications" />
                )}
              </Nav>
            </aside>
            <Tab.Content className="flex-fill overflow-auto pl-3">
              <Tab.Pane eventKey="profile">
                <Profile />
              </Tab.Pane>
              <Tab.Pane eventKey="tokens">
                <Tokens />
              </Tab.Pane>
              {canViewNutshell && !isAdmin && (
                <Tab.Pane eventKey="schedules">
                  <NutshellEmbedFrame
                    siteId={nsSiteId}
                    nsInstanceId={nsId}
                    embedURL="https://nutshell.webfx.com/embed/scheduler"
                    loadingComponent={<NutshellLoading />}
                  />
                </Tab.Pane>
              )}
              {isAdmin && flags.schedulerFxNutshellForFXers && (
                <Tab.Pane eventKey="schedulerFx">
                  <FXerNutshellEmbedframe />
                </Tab.Pane>
              )}
              {canViewNotifications && (
                <Tab.Pane eventKey="emailNotifications">
                  <EmailNotifications viewingUser={user} />
                </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>
        </div>
      </Paper>
    </ContentContainer>
  );
};

const FXerNutshellEmbedframe = () => {
  const embedUrl = 'https://nutshell.webfx.com/embed/scheduler';
  const auth = useNutshellAuth('', 'embed', '365724');
  const { token } = auth?.data || {};
  if (!token) {
    return (
      <div className="mt-5 d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }
  return <NutshellEmbed token={token} embedURL={embedUrl} />;
};

const NavItem = ({ label, icon = '', eventKey, ...passThroughProps }) => {
  const [iconType] = icon.split('_outlined');
  const outlined = icon.endsWith('_outlined');
  return (
    <Nav.Item>
      {
        <Nav.Link
          className={styles.navLink}
          data-fx-name={eventKey}
          eventKey={eventKey}
          {...passThroughProps}
        >
          {iconType && <Icon type={iconType} outlined={!!outlined} className={styles.navIcon} />}
          <span>{label}</span>
        </Nav.Link>
      }
    </Nav.Item>
  );
};
export default MyInfo;
