import { action, createContextStore } from 'easy-peasy';

export const BannerContext = createContextStore({
  route: '',
  logo: null,
  copy: null,
  hideSelector: false,
  bannerAction: null,
  set: action((state, payload) => {
    state.route = payload.route;
    state.logo = payload.logo;
    state.copy = payload.copy;
    state.hideSelector = !!payload.hideSelector;
    state.bannerAction = !!payload.bannerAction;
  }),
  changeBannerAction: action((state, payload) => {
    state.bannerAction = payload;
  }),
});
