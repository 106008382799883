import { useState, useRef } from 'react';
import classnames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { Link, Image, IconLabel, useCan, Button } from '@webfx/core-web';
import { useClickOutside, useFlags } from '@webfx/web-hooks';

import styles from './SupportMenu.module.css';

const supportIcon = '/assets/images/support-icon';
const SupportDropdown = ({ visible }) => {
  const isAdmin = useCan('manage', 'roles');
  const url = useStoreState((state) => state.router.url);

  /**
   * this is to reload the state of the component
   * since it renders a dynamic iframe
   */
  const loadHelpCenter = () => {
    window.location.href = url('helpCenter');
  };

  return (
    <div className={classnames(styles.dropdown, { [styles.dropdownShow]: visible })}>
      <span className="bold text-dark">Help Center Resources</span>
      <Link
        to={isAdmin ? 'https://operationsfx.com/bctodo/internalrd' : 'mailto:app@webfx.com'}
        plain
        className={styles.link}
      >
        <IconLabel
          label="Contact Support"
          icon="headset_mic"
          outlined
          iconClassName="text-secondary"
        />
      </Link>
      {isAdmin && (
        <Link
          to="https://p.datadoghq.com/sb/73mdueb31d5chwiv-81c46dcd7b175a852846c83f47552a9e"
          plain
          className={styles.link}
        >
          <IconLabel
            label="Server Status"
            icon="multiline_chart"
            outlined
            iconClassName="text-secondary"
          />
        </Link>
      )}

      <Link to={url('helpCenter')} className={styles.link} onClick={() => loadHelpCenter()}>
        <IconLabel label="Help Center" icon="help" outlined iconClassName="text-secondary" />
      </Link>
    </div>
  );
};

const UserProfileMenu = () => {
  const [visible, setVisible] = useState(false);
  const flags = useFlags();

  const wrapperRef = useRef();
  useClickOutside(wrapperRef, () => {
    setVisible(false);
  });

  return (
    <div className={styles.wrap} ref={wrapperRef}>
      {flags.helpcenterUpdates ? (
        <Button
          iconClassName="ml-1 font-14"
          size="sm"
          text="Client Knowledge Center"
          className="bg-blue-500 bg-hover-blue-600 font-12 semibold text-white"
          to="/help-center"
        />
      ) : (
        <div className={styles.widgetCol}>
          <OverlayTrigger overlay={<Tooltip>Help Center Resources</Tooltip>} placement="bottom">
            <NavLink to="/help-center">
              <Image
                className="nav-svg"
                src={supportIcon + '.png'}
                srcSet={supportIcon + '@2x.png 2x, ' + supportIcon + '@3x.png 3x'}
                height="22px"
              />
            </NavLink>
          </OverlayTrigger>
        </div>
      )}
      <SupportDropdown visible={visible} />
    </div>
  );
};

export default UserProfileMenu;
