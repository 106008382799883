import React, { useLayoutEffect, useRef } from 'react';
import classnames from 'classnames';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button, Icon, Image, Logo } from '@webfx/core-web';
import { useAuth, useStartPage, useActiveSite, useFlags, useMenu } from '@webfx/web-hooks';

import useApps from '@webfx/common-web/src/hooks/useApps';
import { version } from '../../../../../../../package.json';
import HeaderPan from '../HeaderPan';
import SupportMenu from '../SupportMenu/SupportMenu';
import UserProfileMenu from '../UserProfileMenu/UserProfileMenu';
import ViewingAsAlert from '../ViewingAsAlert/ViewingAsAlert';
import HeaderLink from './HeaderLink/HeaderLink';
import HeaderTab from './HeaderTab/HeaderTab';

const userPermissionsIcon = '/assets/images/icon-user-permissions';
const logo = '/assets/images/logos/webfx-logo';

import styles from './Header.module.css';

const MobileToggle = React.forwardRef(({ onClick, className, ...props }, ref) => (
  <Button
    className={classnames(['text-white border-0 toggle-button', className])}
    icon={props['aria-expanded'] ? 'clear' : 'menu'}
    ref={ref}
    onClick={onClick}
    {...props}
  />
));

const Header = () => {
  const appsRef = useRef(null);
  const Apps = useApps();

  const { setActive, setLastActive } = useStoreActions((actions) => actions.sites);
  const activeApp = useStoreState((state) => state.router.app);
  const { user: currentUser, isAdmin, loggedInUser } = useAuth();
  const startPage = useStartPage();
  const isViewingAs = loggedInUser?.userId !== currentUser?.userId;
  const flags = useFlags();

  const siteId = useActiveSite().siteId;

  const { menuOpen, setMenuOpen } = useMenu();
  const canOpenCloseMenu =
    flags.marketingcloudfxSidebarNavigation &&
    (activeApp === 'marketingcloud' ||
      activeApp === 'analytics' ||
      activeApp === 'competitorspy') &&
    siteId !== null &&
    !isNaN(siteId);

  useLayoutEffect(() => {
    const active = document.querySelector('#app-head .active');
    if (active) {
      active.scrollIntoView();
    }
  }, [activeApp]);

  const hostToLogoMappings = [
    { substring: 'localhost', logo: styles.localLogo },
    { substring: 'app.dev', logo: styles.devLogo },
    { substring: 'app.next', logo: styles.nextLogo },
    { substring: 'app.master', logo: styles.nextLogo },
  ];

  const matchingNonProductionHostToLogoMapping = hostToLogoMappings.find((mapping) =>
    window.location.host.includes(mapping.substring)
  );

  return (
    <header
      className={classnames(styles.header, {
        [styles.viewingAs]: isViewingAs,
      })}
      id="app-head"
    >
      <ViewingAsAlert />

      <div className={styles.menu}>
        <div className={classnames(styles.appLogo, 'd-flex')}>
          {canOpenCloseMenu ? (
            <>
              <div className="d-flex">
                <Button
                  className={classnames([
                    'p-0 text-white align-self-center toggle-button flex-grow-1',
                    styles.toggleButton,
                    {
                      [styles.active]: menuOpen,
                    },
                  ])}
                  icon={menuOpen ? 'clear' : 'menu'}
                  iconClassName="font-24"
                  onClick={() => setMenuOpen(!menuOpen)}
                  data-fx-name="sideBarButton"
                />
              </div>
              <Logo
                linkTo={startPage}
                onClick={() => {
                  if (!((currentUser.sites ?? []).length || isAdmin)) {
                    setActive(null);
                    setLastActive(null);
                  }
                }}
                linkClassName={classnames(
                  styles.toggleLogo,
                  styles.logo,
                  matchingNonProductionHostToLogoMapping?.logo || null
                )}
                title={matchingNonProductionHostToLogoMapping && version}
              />
              <Logo
                linkTo={startPage}
                onClick={() => {
                  if (!((currentUser.sites ?? []).length || isAdmin)) {
                    setActive(null);
                  }
                }}
                imgSrc={logo + '.png'}
                imgSrcSet={logo + '@2x.png 2x, ' + logo + '@3x.png 3x'}
                linkClassName={classnames(styles.toggleLogo, styles.smallLogo)}
                imgClassName="pb-1"
              />
            </>
          ) : (
            <>
              <Logo
                linkTo={startPage}
                onClick={() => {
                  if (!((currentUser.sites ?? []).length || isAdmin)) {
                    setActive(null);
                    setLastActive(null);
                  }
                }}
                linkClassName={classnames(
                  styles.logo,
                  matchingNonProductionHostToLogoMapping?.logo || null
                )}
                title={matchingNonProductionHostToLogoMapping && version}
              />
              <Logo
                linkTo={startPage}
                onClick={() => {
                  if (!((currentUser.sites ?? []).length || isAdmin)) {
                    setActive(null);
                    setLastActive(null);
                  }
                }}
                imgSrc={logo + '.png'}
                imgSrcSet={logo + '@2x.png 2x, ' + logo + '@3x.png 3x'}
                linkClassName={styles.smallLogo}
                imgClassName="pb-1"
              />
            </>
          )}
        </div>
        <div className={styles.tabs} ref={appsRef}>
          <div className={classnames(styles.tabsScrollWrap, 'flex-shrink-0')}>
            {Apps.map(({ text, to, isActive, extraIcon }) => (
              <React.Fragment key={`desktop-header-${text.replace(' ', '-')}`}>
                <HeaderTab text={text} to={to} isActive={isActive} extraIcon={extraIcon} />
              </React.Fragment>
            ))}
          </div>
        </div>
        <Dropdown className={styles.mobileDropdown}>
          <Dropdown.Toggle as={MobileToggle} />
          <Dropdown.Menu className={styles.mobileDropdownMenu}>
            {Apps.map(({ text, to, isActive }) => (
              <Dropdown.Item
                key={`mobile-header-${text.replace(' ', '-')}`}
                className={classnames(['p-0 m-0', styles.mobileTab])}
              >
                <HeaderTab text={text} to={to} isActive={isActive} />
                <Icon className="arrow-icon">arrow_forward_ios</Icon>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <HeaderPan appsRef={appsRef} />
        <div className="mr-3">
          <SupportMenu />
        </div>
        {isAdmin ? (
          <HeaderLink className={`mr-3 ${styles.globalPermissions}`} to="/client-permissions/users">
            <OverlayTrigger overlay={<Tooltip>Global Permissions</Tooltip>} placement="bottom">
              <Image
                className="nav-svg"
                src={userPermissionsIcon + '.png'}
                srcSet={userPermissionsIcon + '@2x.png 2x, ' + userPermissionsIcon + '@3x.png 3x'}
              />
            </OverlayTrigger>
          </HeaderLink>
        ) : null}
        <div className={styles.profile}>
          <UserProfileMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
