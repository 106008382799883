import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

import { Avatar, Icon } from '@webfx/core-web';
import { useAuth } from '@webfx/web-hooks';

import styles from './ViewingAsAlert.module.css';

const ViewingAsAlert = () => {
  const { user: currentUser, loggedInUser, switchBackToLoggedInUser } = useAuth();

  if (!currentUser?.userId || currentUser?.userId === loggedInUser?.userId) {
    return null;
  }

  return (
    <div className={`${styles.main} d-flex justify-content-center align-items-center`}>
      <div className={'mr-2'} data-fx-name="viewingUserName">
        Logged in as:
      </div>
      <div className={'d-flex align-items-center'}>
        <span className={'mr-1'}>
          {currentUser.firstName} {currentUser.lastName}
        </span>
        <Avatar
          className={styles.imgWrap}
          alt="Profile"
          src={currentUser.avatarUrl || 'https://app.webfx.com/assets/images/avatar.png'}
          disableLazyLoad={true}
        />
      </div>
      <div>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Switch back to your user.</Tooltip>}>
          <Button
            onClick={() => {
              switchBackToLoggedInUser();
            }}
            className={`${styles.swapButton}`}
            variant={'link'}
          >
            <Icon>swap_horiz</Icon>
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default ViewingAsAlert;
