import classnames from 'classnames';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useRef, useState } from 'react';

import { Icon, Link, ProfileWidget } from '@webfx/core-web';
import { useAuth, useClickOutside } from '@webfx/web-hooks';
import styles from './UserProfileMenu.module.css';

const ProfileDropdown = ({ visible }) => {
  const logout = useAuth().logout;
  const setLastViewed = useStoreActions((actions) => actions.router.setLastViewed);
  const url = useStoreState((state) => state.router.url);

  const onLogout = async () => {
    logout();
    // clear last viewed on manual logout.
    setLastViewed(null);
  };

  return (
    <div className={classnames(styles.dropdown, { [styles.dropdownShow]: visible })}>
      <Link to={url('myInfo')} className={classnames([styles.link, 'd-flex align-items-center'])}>
        <Icon className={styles.icon} outlined>
          account_circle
        </Icon>{' '}
        My Info
      </Link>
      <button
        type="button"
        onClick={onLogout}
        className={classnames([styles.link, styles.signout, 'd-flex align-items-center'])}
        data-fx-name="logout"
      >
        <Icon className={styles.icon}>exit_to_app</Icon> Logout
      </button>
    </div>
  );
};

const UserProfileMenu = () => {
  const [visible, setVisible] = useState(false);
  const avatarUrl = useAuth().user?.avatarUrl;

  const toggleDropdown = () => setVisible(!visible);

  const wrapperRef = useRef();
  useClickOutside(wrapperRef, () => {
    setVisible(false);
  });

  return (
    <div className={styles.wrap} ref={wrapperRef} data-fx-name="profileWidget">
      <div className={styles.widgetCol}>
        <ProfileWidget
          avatarUrl={avatarUrl || 'https://app.webfx.com/assets/images/avatar.png'}
          handleClick={toggleDropdown}
        />
      </div>
      <ProfileDropdown visible={visible} />
    </div>
  );
};

export default UserProfileMenu;
