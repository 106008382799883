import { useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';
import { ErrorPage } from '@webfx/core-web';

import './NotFoundPage.styles.css';

/**
 *
 */
const NotFoundPage = () => {
  const { url } = useStoreState((state) => state.router);

  return (
    <ErrorPage title="404" subtitle="This page does not exist.">
      <div>
        <p>Please try one of the links below to find what you are looking for:</p>
        <ul className="list-inline mt-4">
          <li className="list-inline-item mr-5">
            <Link className="NotFoundPage-link" to={url({ route: 'mcfxDashboard' })}>
              MarketingCloudFX
            </Link>
          </li>
          <li className="list-inline-item mr-5">
            <Link className="NotFoundPage-link" to="/content-genius">
              ContentGeniusFX
            </Link>
          </li>
        </ul>
      </div>

      <div className="mt-auto pb-5">
        <hr className="mt-5 mb-3 p-0 mx-0" style={{ width: '50px', borderColor: '#000' }} />
        <p>
          Still can’t find what you’re looking for?
          <br />
          Email our support desk at <a href="mailto:app@webfx.com">app@webfx.com</a>.
        </p>
      </div>
    </ErrorPage>
  );
};

export default NotFoundPage;
