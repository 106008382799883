import React from 'react';
import PropTypes from 'prop-types';
import { PageContainer, Snackbar } from '@webfx/core-web';
import { useAuth } from '@webfx/web-hooks';
import classnames from 'classnames';
import Header from '../components/Header';
import { BannerContext } from '../../context/BannerContext';
import ProductBanner from '../components/ProductBanner';

import styles from './AdminLayout.module.css';

const AdminLayout = ({ children }) => {
  const { user, loggedInUser } = useAuth();
  if (!user) {
    return null;
  }
  const isViewingAs = loggedInUser?.userId !== user.userId;

  return (
    <PageContainer viewingAsOther={isViewingAs}>
      <Header />
      <div
        className={classnames('d-flex', {
          [styles.pageBodyViewingAs]: isViewingAs,
        })}
      >
        <aside
          id="side-nav-container"
          className={classnames(styles.sideNav, { [styles.pageBodyViewingAs]: isViewingAs })}
        ></aside>
        <BannerContext.Provider>
          <section
            id="content-area"
            className={classnames(styles.contentWrapper, {
              [styles.pageBodyViewingAs]: isViewingAs,
            })}
          >
            <ProductBanner />
            {children}
          </section>
        </BannerContext.Provider>
      </div>
      <Snackbar autoHideDuration={5000} />
    </PageContainer>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminLayout;
