import { PermissionsAbility as permissions } from '@webfx/core-web';
import { permittedFieldsOf } from '@casl/ability/extra';

export default () => {
  return permissions.rulesFor('manage', 'nutshell')
    ? permittedFieldsOf(permissions, 'manage', 'nutshell', {
        fieldsFrom: (rule) => rule.fields || [],
      })[0]?.nsId
    : null;
};
